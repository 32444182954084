import Toaster from "@meforma/vue-toaster";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import { createApp } from "vue";
import { VueCsvImportPlugin } from "vue-csv-import";
import App from "./App.vue";
import http from "./http";
import "./index.css";
import auth from "./plugins/auth.js";
import PusherPlugin from "./plugins/pusher";
import Sentry from "./plugins/sentry";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";

const app = createApp(App);

app
  .use(PusherPlugin, { apiKey: "2e95b0e04d3f392cfd0b", cluster: "eu" })
  .use(VueGtag, {
    config: { id: "G-796H1ZJTXD" },
  })
  .use(Autocomplete)
  .use(Toaster)
  .use(VueCsvImportPlugin)
  .use(store)
  .use(http)
  .use(router)
  .use(Sentry, app.router)
  .use(auth)
  .mount("#app");
